import { useContext, useEffect, useState } from 'react';
import useTeacherSchoolsFilter from '../../components/SchoolFilterTeacher/teacherschools-filter-hook';
import { SchoolValue } from '../../components/SchoolFilterTeacher';
import { TeacherBenchmarksDashboardContext } from './teacherbenchmarks-dashboard-context';
import { TeacherBenchmarkDashboardStylesCSS } from './teacherbenchmarkdashboard.styled';
import { useTheme } from '@mui/material';
import { DashboardWidgetsDataStatusProps } from '../AdminAnalytics/admin-analytics-hook';
import {
  BenchmarkProgressScoreInput,
  BenchmarkProgressScoreSortType,
  BenchmarkType,
  OrderBy,
  useGetLastSnowflakeUpdateTimeQuery,
} from '../../generated/graphql';
import { useAuthProvider } from '../../core/authContext';
import useLocalStorage from '../../utils/useLocalStorage';
import { useHistory } from 'react-router-dom';
import useTeacherStudentGradesFilter from '../../components/MultiSelectTeacherGradeFilter/teachergrade-hook';

export interface TeacherBenchmarkDashboardFiltersProps {
  schools?: string[];
  grades?: string[];
  classes?: string[];
  teachers?: string[];
}

export const TeacherBenchmarkDashboardContextInit = ({ dashboardWidgets }: any) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus] = useState<DashboardWidgetsDataStatusProps[]>(
    Object.values(dashboardWidgets).map((widgetData: any) => ({
      widget: widgetData.name,
      loading: true,
      dataMissing: false,
    })),
  );
  const [isDataMissingInAllWidgets, setIsDataMissingInAllWidgets] = useState(false);
  const [areWidgetsLoading, setAreWidgetsLoading] = useState(true);
  const [isAdminReportBSGrowthDataMissing, setIsAdminReportBSGrowthDataMissing] = useState(false);

  const localStorageInitValue = {
    schools: [],
    grades: [],
    classes: [],
    teachers: [],
    benchmarkType: BenchmarkType.Boy,
  };

  let dashboard = 'teacher_benchmark_dashboard';
  const {
    localStorageValue: localTeacherBenchmarkDashboardFilters,
    getLocalStorageValue: getLocalTeacherBenchmarkDashboardFilters,
    setLocalStorageValue: setLocalTeacherBenchmarkDashboardFilters,
  } = useLocalStorage({
    keyTag: `${dashboard}_filters`,
    initialValue: localStorageInitValue,
    timeoutMS: 300000,
  });

  const getTeacherBenchmarkFiltersPayloadFromLocal = (localFiltersValue: any = localTeacherBenchmarkDashboardFilters) =>
    localFiltersValue
      ? {
        ...(localFiltersValue?.schools?.length && { school_ids: localFiltersValue.schools }),
        ...(localFiltersValue?.grades?.length && { grade_ids: localFiltersValue.grades }),
        ...(localFiltersValue?.classes?.length && { section_ids: localFiltersValue.classes }),
        ...(localFiltersValue?.teachers?.length && { teacher_ids: localFiltersValue.teachers }),
        ...(localFiltersValue?.benchmarkType?.length && { benchmarkType: localFiltersValue.benchmarkType }),
      }
      : {};

  const [teacherDashboardFilters, setTeacherDashboardFilters] = useState(
    getTeacherBenchmarkFiltersPayloadFromLocal(localTeacherBenchmarkDashboardFilters),
  );
  const queryParams = new URLSearchParams(history.location.search);
  const prevPage = queryParams.get('prevPage');
  const toRestoreFilters = prevPage?.includes('individual');
  const type = teacherDashboardFilters?.benchmarkType || BenchmarkType.Boy;

  // ** currentFilters will save the individual filter states as a whole in context, useful to revert from un-applied user changes to them **
  const [selectedGrades, setSelectedGrades] = useState<string[]>(
    toRestoreFilters ? teacherDashboardFilters?.grade_ids || [] : [],
  );
  const [selectedClasses, setSelectedClasses] = useState<string[]>(
    toRestoreFilters ? teacherDashboardFilters?.section_ids || [] : [],
  );
  const [selectedSchools, setSelectedSchools] = useState<string[]>(
    toRestoreFilters ? teacherDashboardFilters?.school_ids || [] : [],
  );
  const [selectedTeachers, setSelectedTeachers] = useState<string[]>(
    toRestoreFilters ? teacherDashboardFilters?.teacher_ids || [] : [],
  );
  const [benchmarkType, setBenchmarkType] = useState<BenchmarkType>(toRestoreFilters ? type : BenchmarkType.Boy);

  useEffect(() => {
    setLocalTeacherBenchmarkDashboardFilters?.({
      classes: selectedClasses,
      grades: selectedGrades,
      schools: selectedSchools,
      teachers: selectedTeachers,
      benchmarkType,
    });
  }, [
    selectedGrades,
    selectedClasses,
    selectedSchools,
    selectedTeachers,
    benchmarkType,
    setLocalTeacherBenchmarkDashboardFilters,
  ]);

  useEffect(() => {
    if (toRestoreFilters) {
      setSelectedClasses(teacherDashboardFilters?.section_ids || []);
      setSelectedGrades(teacherDashboardFilters?.grade_ids || []);
      setSelectedSchools(teacherDashboardFilters?.school_ids || []);
      setBenchmarkType(teacherDashboardFilters?.benchmarkType || BenchmarkType.Boy);
    }
  }, [teacherDashboardFilters, toRestoreFilters]);

  const onSchoolChange = (schoolData: SchoolValue[] | string[]) => {
    setSelectedClasses([]);
    setSelectedSchools(schoolData as string[]);
  };

  const onClassChange = (classData: any[] | string[]) => {
    setSelectedClasses(classData as string[]);
  };

  const onGradeChange = (gradesData: any[] | string[]) => {
    setSelectedTeachers([]);
    setSelectedGrades(gradesData as string[]);
  };

  const onTeacherChange = (teachersData: any[] | string[]) => {
    setSelectedTeachers(teachersData as string[]);
  };

  return {
    teacherDashboardFilters,
    setTeacherDashboardFilters,
    getLocalTeacherBenchmarkDashboardFilters,
    setLocalTeacherBenchmarkDashboardFilters,
    localTeacherBenchmarkDashboardFilters,
    loading,
    setLoading,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    areWidgetsLoading,
    setAreWidgetsLoading,
    selectedClasses,
    setSelectedClasses,
    selectedGrades,
    setSelectedGrades,
    selectedTeachers,
    setSelectedTeachers,
    selectedSchools,
    setSelectedSchools,
    onSchoolChange,
    onClassChange,
    onGradeChange,
    onTeacherChange,
    benchmarkType,
    setBenchmarkType,
    isAdminReportBSGrowthDataMissing,
    setIsAdminReportBSGrowthDataMissing,
  };
};

export const useTeacherBenchmarksDashboardContext = () => {
  const theme = useTheme();
  const {
    selectedGrades,
    selectedClasses,
    selectedSchools,
    selectedTeachers,
    setSelectedClasses,
    setSelectedGrades,
    setSelectedSchools,
    setSelectedTeachers,
    areWidgetsLoading,
    setAreWidgetsLoading,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    loading,
    setLoading,
    benchmarkType,
    setBenchmarkType,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
    isProgressScoresLoading,
    setIsProgressScoresLoading,
    isProgressData,
    setIsProgressData,
    progressScoreDataCount,
    progressScoreQueryInput,
    setProgressScoreQueryInput,
    setProgressScoreDataCount,
    legendDataOverallPerformanceWidget,
    setLegendDataOverallPerformanceWidget,
    getLocalTeacherBenchmarkDashboardFilters,
    setLocalTeacherBenchmarkDashboardFilters,
    localTeacherBenchmarkDashboardFilters,
    isAdminReportBSGrowthDataMissing,
    setIsAdminReportBSGrowthDataMissing,
  } = useContext(TeacherBenchmarksDashboardContext);

  const styles = TeacherBenchmarkDashboardStylesCSS(theme);
  const skipGraphQLRequest = () =>
    !selectedGrades?.length! && !selectedClasses?.length! && !selectedTeachers?.length && !selectedSchools?.length!;
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const getTagStyleClass = (score: number) => {
    if (score === null || score === undefined) return styles.tagDisabled;
    if (0 < score && score < 1.5) return styles.tagRed;
    if (1.5 <= score && score < 2.5) return styles.tagYellow;
    if (2.5 <= score && score < 3.5) return styles.tagGreen;
    if (3.5 <= score && score < 4.5) return styles.tagBlue;
    if (4.5 <= score && score <= 5) return styles.tagPurple;
    return styles.tagDisabled;
  };
  // Insufficient Data Code Block
  const checkDataMissingInAllWidgets = () =>
    dashboardWidgetsDataStatus?.map((widgetData) => widgetData.dataMissing).every((dataMissing) => dataMissing);
  const checkIfAnyWidgetStillLoading = () =>
    dashboardWidgetsDataStatus?.map((widgetData) => widgetData.loading).some((flag) => flag === true);
  const updateDashboardWidgetsDataStatus = ({
    widget,
    loading: widgetLoading,
    dataMissing,
  }: DashboardWidgetsDataStatusProps) => {
    setDashboardWidgetsDataStatus?.(
      dashboardWidgetsDataStatus?.map((dashboardWidget) =>
        dashboardWidget.widget !== widget
          ? dashboardWidget
          : { widget, loading: widgetLoading, dataMissing: dataMissing ?? dashboardWidget.dataMissing },
      ),
    );
  };
  const updateProgressData = (data: any, loading: boolean) => {
    if (!loading) {
      setIsProgressData?.(data?.benchmarkProgressScore?.total_count === 0 ? false : true);
    }
    setIsProgressScoresLoading?.(loading);
  };
  // resets the data flags of all widgets before the new set of requests due to new filters or user actions.
  const resetDashboardWidgetsDataStatus = (widgetLoading: boolean, widgetDataMissing?: boolean) => {
    const newDashboardWidgetsDataStatus: DashboardWidgetsDataStatusProps[] = [];
    dashboardWidgetsDataStatus?.forEach((widgetDataStatus) => {
      let newWidgetDataStatus: any = {};
      if (widgetLoading !== undefined) newWidgetDataStatus = { loading: widgetLoading, ...newWidgetDataStatus };
      if (widgetDataMissing !== undefined)
        newWidgetDataStatus = { dataMissing: widgetDataMissing, ...newWidgetDataStatus };
      newDashboardWidgetsDataStatus.push({
        ...widgetDataStatus,
        ...newWidgetDataStatus,
      });
    });
    setDashboardWidgetsDataStatus?.([...newDashboardWidgetsDataStatus]);
  };

  // update isDataMissingInAllWidgets when there is change in the data-Status in each widget
  useEffect(() => {
    setIsDataMissingInAllWidgets?.(checkDataMissingInAllWidgets());
    setAreWidgetsLoading?.(checkIfAnyWidgetStillLoading() || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardWidgetsDataStatus]);

  useEffect(() => {
    setIsFilterSelected(
      selectedClasses?.length! > 0 ||
        selectedGrades?.length! > 0 ||
        selectedSchools?.length! > 0 ||
        selectedTeachers?.length! > 0,
    );
  }, [selectedGrades, selectedClasses, selectedSchools, selectedTeachers]);
  return {
    selectedGrades,
    setSelectedGrades,
    selectedClasses,
    selectedSchools,
    selectedTeachers,
    skipGraphQLRequest,
    getTagStyleClass,
    areWidgetsLoading,
    setAreWidgetsLoading,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    updateDashboardWidgetsDataStatus,
    checkDataMissingInAllWidgets,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    resetDashboardWidgetsDataStatus,
    isFilterSelected,
    loading,
    setLoading,
    benchmarkType,
    setBenchmarkType,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
    isProgressScoresLoading,
    setIsProgressScoresLoading,
    isProgressData,
    setIsProgressData,
    updateProgressData,
    setProgressScoreQueryInput,
    progressScoreDataCount,
    progressScoreQueryInput,
    setProgressScoreDataCount,
    legendDataOverallPerformanceWidget,
    setLegendDataOverallPerformanceWidget,
    getLocalTeacherBenchmarkDashboardFilters,
    setLocalTeacherBenchmarkDashboardFilters,
    localTeacherBenchmarkDashboardFilters,
    setSelectedClasses,
    setSelectedSchools,
    setSelectedTeachers,
    isAdminReportBSGrowthDataMissing,
    setIsAdminReportBSGrowthDataMissing,
  };
};
const useTeacherBenchmarksDashboard = ({
  selectedClasses,
  selectedGrades,
  selectedSchools,
}: any) => {
  const { getUser } = useAuthProvider();
  const user = getUser();
  const [isProgressData, setIsProgressData] = useState(false);
  const [isProgressScoresLoading, setIsProgressScoresLoading] = useState(true);
  const queryInput: BenchmarkProgressScoreInput = {
    section_ids: selectedClasses ? selectedClasses : [],
    school_ids: selectedSchools ? selectedSchools : [],
    grade_ids: selectedGrades ? selectedGrades : [],
    sort_by: BenchmarkProgressScoreSortType.StudentName,
    order_by: OrderBy.Asc,
    page: 1,
  };

  const [progressScoreQueryInput, setProgressScoreQueryInput] = useState<BenchmarkProgressScoreInput>({
    ...queryInput,
  });
  const [progressScoreDataCount, setProgressScoreDataCount] = useState<number>(0);
  const [legendDataOverallPerformanceWidget, setLegendDataOverallPerformanceWidget] = useState<any[]>([]);
  const { schools: schoolsData, loading: loadingSchools } = useTeacherSchoolsFilter();

  const { grades } = useTeacherStudentGradesFilter();


  const { data: lastSnowflakeUpdateTimeData, loading: getLastSnowflakeUpdateTimeLoading } =
    useGetLastSnowflakeUpdateTimeQuery({
      fetchPolicy: 'network-only',
    });



  return {
    schoolsData,
    loadingSchools,
    lastSnowflakeUpdateTimeData: lastSnowflakeUpdateTimeData?.getLastSnowflakeUpdateTime,
    getLastSnowflakeUpdateTimeLoading,
    userId: user?.id ?? '',
    isProgressData,
    isProgressScoresLoading,
    setIsProgressData,
    setIsProgressScoresLoading,
    progressScoreQueryInput,
    setProgressScoreQueryInput,
    progressScoreDataCount,
    setProgressScoreDataCount,
    legendDataOverallPerformanceWidget,
    setLegendDataOverallPerformanceWidget,
    grades,
  };
};
export default useTeacherBenchmarksDashboard;
